import styled from "styled-components"

export const Img = styled.img`
    width: 100%;
    opacity: 1;
    transition: opacity .3s ease-in;
`

//img[data-src] {
//    opacity: 0;
//}
